import React from 'react';
import styled from '@emotion/styled';

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  flex-direction: column; /* Added */
`;


const Image = styled.img`
  width: 200px; /* Adjust the size of the image as needed */
`;

const Image1 = styled.img`
  width: 50px; /* Adjust the size of the image as needed */
`;

const Image2 = styled.img`
  width: 350px; /* Adjust the size of the image as needed */
`;

const Title = styled.h3`
  font-size: 12px;
  margin-top: 10px;
  font-family: 'Circular Std', sans-serif; /* Specify the round font family */
`;


function App() {
  return (
    <Container>
      <Image2 src="https://pbs.twimg.com/media/GInGz2kbYAAjn2d.jpg" alt="Image" />
      <Image src="https://pbs.twimg.com/media/GImhj0wbAAAMl7P.jpg" alt="Image" />
      <Image1 src="https://media.tenor.com/Xa8qp8J8DMgAAAAi/party-blob-dance.gif" alt="Image" />
      <Title> DEVS DID SOMETHING! 4844 BLOBS!</Title>
      <Title> <a href="https://app.uniswap.org/explore/tokens/base/0xd8997c2088a9cd232af79b97738c3684e0287d48"> BUY ON UNISWAP </a> </Title>
      <Title> <a href="https://dexscreener.com/base/0xe0aa9a2c779fa7582f2943057cf087060145f113"> DEXSCREENER </a> </Title>
      <Title> <a href="https://t.me/BlobParty"> TELEGRAM </a></Title>
      <Title> <a href="https://warpcast.com/~/channel/blobparty"> FARCASTER </a> </Title>
      <Title> <a href="https://x.com/blobpartybase"> TWITTER </a></Title>
    </Container>
  );
}

export default App;
